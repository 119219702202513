import React, { Component } from "react"
import TitleAndText from "./title-and-text"
import FullImageOrMovie from "./full-image-or-movie"
import Youtube from "./youtube"
import Slider from "./slider"
import Gallery from "./gallery"
import GalleryPage from "./gallery-page"
import Numbers from "./numbers"
import AlltidAldrig from "./alltid-aldrig"
import TextBlurbs from "./text-blurbs"

class Modules extends Component {
    
    loopFields() {
        const modules = this.props.data
        let modulesArray = [];
        console.log(modules);
        modules.forEach(element => {
            if (element.__typename === 'WordPressAcf_title_and_text') {
                modulesArray.push(
                    <TitleAndText key={element.id} data={element} />
                )
            } else if (element.__typename === 'WordPressAcf_gallery') {
                modulesArray.push(
                    <Gallery key={element.id} data={element} />
                )
            } else if (element.__typename === 'WordPressAcf_gallery_page') {
                modulesArray.push(
                    <GalleryPage key={element.id} data={element} />
                )
            } else if (element.__typename === 'WordPressAcf_full_image_or_movie') {
                modulesArray.push(
                    <FullImageOrMovie key={element.id} data={element} />
                )
            } else if (element.__typename === 'WordPressAcf_youtube') {
                modulesArray.push(
                    <Youtube key={element.id} data={element} />
                )
            } else if (element.__typename === 'WordPressAcf_slider') {
                modulesArray.push(
                    <Slider key={element.id} data={element} />
                )
            } else if (element.__typename === 'WordPressAcf_numbers') {
                modulesArray.push(
                    <Numbers key={element.id} data={element} />
                )
            } else if (element.__typename === 'WordPressAcf_alltid_aldrig') {
                modulesArray.push(
                    <AlltidAldrig key={element.id} data={element} />
                )
            } else if (element.__typename === 'WordPressAcf_text_blurbs') {
                modulesArray.push(
                    <TextBlurbs key={element.id} data={element} />
                )
            } 
        });
        return modulesArray;
    }

    render() {
        return(
            <section className="modules flex flex-wrap">
                {this.loopFields()}
            </section>
        )
    }
}

export default Modules