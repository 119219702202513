import React, { useState } from "react"
import Swiper from 'react-id-swiper';
import { Navigation } from 'swiper/dist/js/swiper.esm'
import Img from "gatsby-image"
// import feather from "feather-icons"
// feather.replace()
const NonStretchedImage = props => {
    let normalizedProps = props
    if (props.fluid && props.fluid.presentationWidth) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                maxWidth: props.fluid.presentationWidth,
            },
        }
    }

    return <Img {...normalizedProps} />
}
const Slider = (props) => {
    const [swiper, updateSwiper] = useState('');
    const params = {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    };
    
    function renderImages() {
        let imgArray = [];
        for (let i = 0; i < props.data.images.length; i++) {
            let image = props.data.images[i];
            imgArray.push(
                <NonStretchedImage className="my-auto" key={image.id} fluid={image.localFile.childImageSharp.fluid} />
            );
        }
        return imgArray;
    }

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    return (
        <section className="slider w-full my-75 md:my-100 xl:my-150">
            <Swiper {...params} getSwiper={updateSwiper} modules={[Navigation]}>
                {renderImages()}
            </Swiper>
            <nav className="flex mt-70 justify-end">
                <button className="focus:outline-none" onClick={goPrev}>
                    <i className="link-standard"
                    width="24"
                    height="24"
                    strokeWidth="1"
                    data-feather="arrow-left" ></i>
                </button>
                <span className="link-standard h-full mx-4 pt-px wide:pt-0">Scrolla</span>
                <button className="focus:outline-none" onClick={goNext}>
                    <i className="link-standard"
                        width="24"
                        height="24"
                        strokeWidth="1"
                        data-feather="arrow-right" ></i>
                </button>
            </nav>
        </section>
    );
};

export default Slider