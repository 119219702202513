import React, { Component } from "react"
import Img from "gatsby-image"

class Gallery extends Component {
    constructor(props) {
        super(props)
        this.images = this.props.data.images;
        this.layout = this.props.data.layout;
    }

    calculateLayout(i) {
        let width = 'w-full';
        console.log(this.layout);
        if (this.layout === 'second_full') {
            if ((i + 1) % 3 === 0) {
                width = 'w-full';
            } else {
                width = 'w-full md:w-1/2';
            }
        } else if (this.layout === 'first_full') {
            if (i % 3 === 0) {
                width = 'w-full';
            } else {
                width = 'w-full md:w-1/2';
            }
        }
        return width;
    }


    renderImages() {
        let imgArray = [];
        for (let i = 0; i < this.images.length; i++) {
            let image = this.images[i];
            let width = this.calculateLayout(i);
            imgArray.push(
                <div key={i} className={"gallery mb-5 md:mb-6 lg:mb-8 wide:mb-50 md:px-3 lg:px-4 wide:px-6 " + width}>
                    <Img className="w-full h-full" fluid={image.localFile.childImageSharp.fluid} />
                </div>
            );
        }
        return imgArray;
    }

    render() {
        return (
            <section className="gallery w-full my-75 md:my-100 xl:my-150">
                <div className="flex flex-wrap lg:-mx-4 wide:-mx-6">
                    {this.renderImages()}
                </div>
            </section>
        )
    }
}

export default Gallery