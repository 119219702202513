import React, { Component } from "react"
import CountUp, { start } from 'react-countup';
import emergence from 'emergence.js';

class Numbers extends Component {

    componentDidMount() {
        emergence.init({
            container: window,
            reset: false,
            handheld: true,
            throttle: 250,
            elemCushion: 0.15,
            offsetTop: 0,
            offsetRight: 0,
            offsetBottom: 0,
            offsetLeft: 0,
            callback: function (element, state) {
                if (state === 'visible') {
                    console.log('Element is visible.');
                }
            }
        });
    }

    renderCountUp() {
        // console.log(this.props.data);
        return this.props.data.numbers
            .map((data, index) => 
                <div key={index} className="w-1/3 text-center" data-emergence="hidden">
                    <CountUp separator=" " suffix={data.unit} className="title-xl text-teal" end={data.number} />
                    <p className="text-large text-white">{data.text}</p>
                </div>
            )
    }

    render() {
        return (
            <section className="numbers flex flex-wrap w-full my-75 md:my-100 xl:my-150">
                {this.renderCountUp()}
            </section>
        )
    }
}

export default Numbers