import React, { Component } from "react"
import Img from "gatsby-image"

class FullImageOrMovie extends Component {

    movieOrImage() {
        const data = this.props.data;
        if (data.media === 'movie') {
            return (
                <video className="w-full" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true">
                    <source src={data.movie.localFile.publicURL} type="video/mp4" />
                </video>
            )
        } else if (data.media === 'image') {
            return (<Img fluid={data.image.localFile.childImageSharp.fluid} />)
        } else {
            return null;
        }
    }
    render() {
        // console.log(this.props.data);
        return (
            <section className="image-or-video w-full my-75 md:my-100 xl:my-150">
                {this.movieOrImage()}
            </section>
        )
    }
}

export default FullImageOrMovie