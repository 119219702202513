
import React, { Component } from "react"
import Bg from './heroBg.js';
import bgSrc from "../../assets/images/bg/rosa_bla.svg"

class Hero extends Component {

    render() {
        return (
            <div className="hero h-screen md:max-h-80 flex items-center text-center justify-center">
                {this.props.children}
            </div>
        )
    }
}


export default Hero
