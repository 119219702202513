import React, { Component } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Hero from "../components/background/hero"
import Layout from "../layouts/layout"
import Modules from "../components/modules/modules"

class PageTemplate extends Component {

  render() {
    const currentPage = this.props.data.wordpressPage
    let shareImage = null;
    // if (currentPage.acf.landscape_case) {
    //     shareImage = currentPage.acf.landscape_case.localFile.publicURL
    // }
    return (
        <Layout footerfixed={false} headertransparent={true} transparent={true}>
            <SEO
                title={currentPage.title}
                description={currentPage.content}
                image={shareImage}
                keywords={['Bulldozer', 'kommunikationsbyrå', 'strategi', 'webb']}
            />
            <Hero>
                <h1 className="title-xxl" dangerouslySetInnerHTML={{ __html: currentPage.acf.title }} />
            </Hero>
            <div className="bg-black">
                <main className="container px-5 pt-50">
                    <Modules data={currentPage.acf.modules_page} />
                </main>
            </div>
        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
        title
        content
        path
        acf {
            title
            modules_page {
                ... on WordPressAcf_title_and_text {
                    id
                    title
                    text
                }
                ... on WordPressAcf_gallery_page {
                    id
                    images {
                        caption
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1700, quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
                ... on WordPressAcf_alltid_aldrig {
                    id
                    alltid
                    aldrig
                }
                ... on WordPressAcf_text_blurbs {
                    id
                    blurb {
                        title
                        text
                    }
                }
            }
        }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`