import React, { Component } from "react"

class TitleAndText extends Component {
    
    render() {
        return (
            <section className="title-and-text w-full my-75 md:my-100 xl:my-150 md:pl-50 lg:pl-70 wide:pl-150">
                <div className="lg:w-2/3 xl:w-1/2 ">
                    <h1 className="title-lg" dangerouslySetInnerHTML={{ __html: this.props.data.title }} />
                    <article className="text-large text-white mt-10 md:mt-50" dangerouslySetInnerHTML={{ __html: this.props.data.text }} />
                </div>
            </section>
        )
    }
}

export default TitleAndText