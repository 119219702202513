import React, { Component } from "react"

class Youtube extends Component {

    render() {
        return (
            <section className="title-and-text w-full my-75 md:my-100 xl:my-150">
                <div className="video-container">
                    <div className="video-inner-container">
                        <iframe
                            title="Youtube"
                            width="100%" 
                            height="100%" 
                            src={"https://www.youtube-nocookie.com/embed/" + (this.props.data.playlist === 'true' ? 'videoseries?list=' : '') + this.props.data.youtube_id + (this.props.data.playlist === 'true' ? '&' : '?modestbranding=1&amp;') + "showinfo=0" }
                            frameBorder="0" 
                            allow="autoplay; encrypted-media" 
                            allowFullScreen=""></iframe>
                    </div>
                </div>
            </section>
        )
    }
}

export default Youtube