import React, { Component } from "react"

class AlltidAldrig extends Component {
    
    render() {
        return (
            <section className="alltid-aldrig w-full my-75 md:my-100 xl:my-150 md:pl-50 wide:pl-70">
                <div className="w-full lg:w-2/3 flex mx-auto flex-wrap">
                    <div className="w-full md:w-1/2 flex md:justify-center mb-70 md:mb-0">
                        <div className="">
                            <div className="relative inline-block mb-10">
                                <h2 className="title-lg">
                                    Alltid
                                </h2>
                                <span className="border-b-6 md:border-b-8 lg:border-b-10 border-teal block absolute w-full"></span>
                            </div>
                            <p className="text-xl md:text-22 lg:text-2xl text-white leading-loose" dangerouslySetInnerHTML={{ __html: this.props.data.alltid }} />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 flex md:justify-center">
                        <div>
                            <div className="relative inline-block mb-10">
                                <h2 className="title-lg">
                                    Aldrig
                                </h2>
                                <span className="border-b-6 md:border-b-8 lg:border-b-10 border-red block absolute w-full" style={{ marginTop: '-25%' }}></span>
                            </div>
                            <div className="text-xl md:text-22 lg:text-2xl text-white leading-loose" dangerouslySetInnerHTML={{ __html: this.props.data.aldrig }} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AlltidAldrig