import React, { Component } from "react"

class TextBlurbs extends Component {

    renderBlurbs() {
        console.log(this.props.data.blurb);
        return this.props.data.blurb
            .map((blurb, index) => 
                <div key={blurb.title + '-' + index} className="blurb w-full md:w-1/2 md:px-4 lg:px-12 py-8 lg:my-50">
                    <h3 className="title-lg mb-6 md:mb-8 lg:mb-50" dangerouslySetInnerHTML={{ __html: blurb.title }} />
                    <p className="text-large text-white" dangerouslySetInnerHTML={{ __html: blurb.text }} />
                </div>
            )
    }
    render() {
        return (
            <section className="text-blurbs w-full my-75 md:my-100 xl:my-150 md:px-50 lg:px-70 wide:px-150 ">
                <div className="flex flex-wrap md:-mx-4 lg:-mx-12 -my-8 lg:-my-50 ">
                    {this.renderBlurbs()}
                </div>
            </section>
        )
    }
}

export default TextBlurbs